<template>
  <div v-if="computedData" class="card">
    <div class="card-body">
      <h5 class="custom-card-title">
        <i class="fas fa-paperclip"></i>
        <span>ÜRÜN DOSYALARI</span>
      </h5>
      <hr />

      <div class="d-block mb-2">
        <!-- Uploader -->
        <WeFileUploader
          multiple="true"
          count-limit="3"
          v-model="computedData"
        />
        <!-- ./Uploader -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductAttachment",
  props: {
    data: {
      default: null,
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>